import React from "react"
import  ContactUsSection from "../components/sections/ContactUsSection";
import Navbar from "../components/sections/Navbar";
import SEO from "../components/seo";

const ContactPage = () => (
  <div className="contact-page">
    <Navbar/>
    <SEO title="Contact Us!" />
    <ContactUsSection />
  </div>
)

export default ContactPage
